/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
html,
body,
#root {
  height: 100%;
}
.colorWeak {
  -webkit-filter: invert(80%);
          filter: invert(80%);
}
.ant-layout {
  min-height: 100vh;
}
.ant-pro-sider.ant-layout-sider.ant-pro-sider-fixed {
  left: unset;
}
canvas {
  display: block;
}
body {
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul,
ol {
  list-style: none;
}
@media (max-width: 480px) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > th,
  .ant-table-thead > tr > td,
  .ant-table-tbody > tr > td {
    white-space: pre;
  }
  .ant-table-thead > tr > th > span,
  .ant-table-tbody > tr > th > span,
  .ant-table-thead > tr > td > span,
  .ant-table-tbody > tr > td > span {
    display: block;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body .ant-design-pro > .ant-layout {
    min-height: 100vh;
  }
}
.ant-form-vertical .ant-form-item-label {
  flex: 0 0 auto !important;
}
.ant-dropdown-menu {
  width: 105px !important;
}
.ant-form-item-label > label {
  font-weight: bold;
}
.ant-form-vertical .ant-pro-form-group {
  display: flex;
  padding: 50px 0 10px;
  border-top: 1px solid #d9d9d9;
}
.ant-form-vertical .ant-pro-form-group .ant-pro-form-group-title {
  flex: 0 0 120px;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-transform: uppercase;
}
.ant-form-vertical .ant-pro-form-group:first-of-type {
  padding-top: 20px;
  border-top: none;
}
.ant-form-vertical .ant-pro-form-group .ant-form-item-extra {
  order: -1;
  margin: 8px 0 12px;
}
.ant-form-vertical .ant-pro-form-group .ant-space-align-start {
  align-items: stretch;
}
.ant-form-vertical .ant-pro-form-group .ant-form-item {
  height: calc(100% - 20px);
}
.ant-form-vertical .ant-pro-form-group .ant-form-item-control {
  flex-grow: 0;
  width: 100%;
  margin-top: auto;
}
.ant-menu-submenu-title[data-menu-id$='users'] {
  color: #f2d434 !important;
}
.ant-menu-submenu-title[data-menu-id$='users'] * {
  color: #f2d434 !important;
}
.ant-menu-sub[id$='users-popup'] .ant-menu-item::after {
  background-color: #f2d434;
  border-right-color: #f2d434;
}
.ant-menu-sub[id$='users-popup'] .ant-menu-item a:hover {
  color: #333;
}
.ant-menu-sub[id$='users-popup'] .ant-menu-item-active,
.ant-menu-sub[id$='users-popup'] .ant-menu-item-selected {
  color: #333;
  background-color: rgb(#f2d434 0.1);
}
.ant-menu-sub[id$='users-popup'] .ant-menu-item-selected a {
  color: #333;
}
.ant-menu-submenu-title[data-menu-id$='sales'] {
  color: #7b61ff !important;
}
.ant-menu-submenu-title[data-menu-id$='sales'] * {
  color: #7b61ff !important;
}
.ant-menu-sub[id$='sales-popup'] .ant-menu-item::after {
  background-color: #7b61ff;
  border-right-color: #7b61ff;
}
.ant-menu-sub[id$='sales-popup'] .ant-menu-item a:hover {
  color: #333;
}
.ant-menu-sub[id$='sales-popup'] .ant-menu-item-active,
.ant-menu-sub[id$='sales-popup'] .ant-menu-item-selected {
  color: #333;
  background-color: rgb(#7b61ff 0.1);
}
.ant-menu-sub[id$='sales-popup'] .ant-menu-item-selected a {
  color: #333;
}
.ant-menu-submenu-title[data-menu-id$='courses'] {
  color: #28bd25 !important;
}
.ant-menu-submenu-title[data-menu-id$='courses'] * {
  color: #28bd25 !important;
}
.ant-menu-sub[id$='courses-popup'] .ant-menu-item::after {
  background-color: #28bd25;
  border-right-color: #28bd25;
}
.ant-menu-sub[id$='courses-popup'] .ant-menu-item a:hover {
  color: #333;
}
.ant-menu-sub[id$='courses-popup'] .ant-menu-item-active,
.ant-menu-sub[id$='courses-popup'] .ant-menu-item-selected {
  color: #333;
  background-color: rgb(#28bd25 0.1);
}
.ant-menu-sub[id$='courses-popup'] .ant-menu-item-selected a {
  color: #333;
}
.ant-menu-submenu-title[data-menu-id$='analytics'] {
  color: #f47820 !important;
}
.ant-menu-submenu-title[data-menu-id$='analytics'] * {
  color: #f47820 !important;
}
.ant-menu-sub[id$='analytics-popup'] .ant-menu-item::after {
  background-color: #f47820;
  border-right-color: #f47820;
}
.ant-menu-sub[id$='analytics-popup'] .ant-menu-item a:hover {
  color: #333;
}
.ant-menu-sub[id$='analytics-popup'] .ant-menu-item-active,
.ant-menu-sub[id$='analytics-popup'] .ant-menu-item-selected {
  color: #333;
  background-color: rgb(#f47820 0.1);
}
.ant-menu-sub[id$='analytics-popup'] .ant-menu-item-selected a {
  color: #333;
}
.ant-menu-submenu-title[data-menu-id$='configuration'] {
  color: #7b8fa2 !important;
}
.ant-menu-submenu-title[data-menu-id$='configuration'] * {
  color: #7b8fa2 !important;
}
.ant-menu-sub[id$='configuration-popup'] .ant-menu-item::after {
  background-color: #7b8fa2;
  border-right-color: #7b8fa2;
}
.ant-menu-sub[id$='configuration-popup'] .ant-menu-item a:hover {
  color: #333;
}
.ant-menu-sub[id$='configuration-popup'] .ant-menu-item-active,
.ant-menu-sub[id$='configuration-popup'] .ant-menu-item-selected {
  color: #333;
  background-color: rgb(#7b8fa2 0.1);
}
.ant-menu-sub[id$='configuration-popup'] .ant-menu-item-selected a {
  color: #333;
}
.ant-menu-submenu-title[data-menu-id$='other'] {
  color: #ba25bd !important;
}
.ant-menu-submenu-title[data-menu-id$='other'] * {
  color: #ba25bd !important;
}
.ant-menu-sub[id$='other-popup'] .ant-menu-item::after {
  background-color: #ba25bd;
  border-right-color: #ba25bd;
}
.ant-menu-sub[id$='other-popup'] .ant-menu-item a:hover {
  color: #333;
}
.ant-menu-sub[id$='other-popup'] .ant-menu-item-active,
.ant-menu-sub[id$='other-popup'] .ant-menu-item-selected {
  color: #333;
  background-color: rgb(#ba25bd 0.1);
}
.ant-menu-sub[id$='other-popup'] .ant-menu-item-selected a {
  color: #333;
}
