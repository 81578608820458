.ant-modal-content {
  padding-top: 50px;
}

.ant-tabs-content .ant-tabs-tabpane {
  padding: 24px;
}

.ant-tabs-content .ant-tabs-tabpane.program {
  padding: 0;
}

.ant-pro-global-header-logo a img {
  height: 20px;
}